import './VerticalSlider.scss';

import { b2x } from '@b2x/react/src';
import { SwiperSlide } from 'swiper/react';

import { HeaderContentType } from '../contentTypes';
import { Slider } from './Slider';
export interface VerticalSliderProps extends HeaderContentType {}

export const VerticalSlider = ({ messagesList }: VerticalSliderProps) => {
  return (
    <Slider
      autoplay={{ delay: messagesList && messagesList.length > 1 ? 3000 : undefined }}
      className="VerticalSlider"
      loop
      slidesPerView={1}
      vertical
    >
      {messagesList?.map((slide, index) => (
        <SwiperSlide key={slide.title}>
          <b2x.ConditionalWrapper
            condition={slide.url !== undefined && slide.url !== ''}
            wrapper={<b2x.router.Link className="text-reset text-decoration-none" to={slide.url} />}
          >
            <div className="slide-text text-uppercase extra-small lh-sm fw-light">{b2x.formatHtml(slide.title)}</div>
          </b2x.ConditionalWrapper>
        </SwiperSlide>
      ))}
    </Slider>
  );
};
