import './SliderB.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { Container } from '../Container';
import { ProductTile } from '../ProductTile';
import { Slider } from './Slider';
import { SliderButton } from './SliderButton';
export interface SliderBProps {
  products: Array<b2x.ProductApiDto>;
}

export const SliderB = ({ products }: SliderBProps) => {
  const sliderUUID = React.useMemo(() => `slider-${b2x.createUUID()}`, []);

  return (
    <div className={`${sliderUUID} slider-b`}>
      <div className="outer-container">
        <Container>
          <b2x.EqualHeight>
            <Slider
              className="overflow-visible"
              navigation
              responsive={{
                md: { slidesPerView: 3.25 },
                sm: { slidesPerView: 2.25 },
                xl: { slidesPerView: 4 },
              }}
              sliderNextEl={`.${sliderUUID} .swiper-button-next`}
              sliderPrevEl={`.${sliderUUID} .swiper-button-prev`}
              slidesPerView={1.1}
              spaceBetween={16}
              watchSlidesProgress
            >
              {products.map((product) => (
                <SwiperSlide key={product.id}>{(slideData) => <ProductTile product={product} />}</SwiperSlide>
              ))}
            </Slider>
          </b2x.EqualHeight>
        </Container>
        <SliderButton direction="left" size="small" />
        <SliderButton direction="right" size="small" />
      </div>
    </div>
  );
};
