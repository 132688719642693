import './CustomRadio.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

export interface CustomRadioProps {
  isSquare?: boolean;
  sku: b2x.SkuApiDto;
}

export const CustomRadio = ({ isSquare, sku, ...props }: CustomRadioProps) => {
  return (
    <div
      className={classnames(
        'CustomRadio d-flex align-items-center justify-content-center',
        isSquare ? 'rectangular-custom-radio px-2' : 'rounded-circle'
      )}
    >
      {sku.attributes?.find((attribute) => attribute.typeCode === 'MIA_Icona_Colore')?.value && (
        <b2x.Image fluid src={sku.attributes.find((attribute) => attribute.typeCode === 'MIA_Icona_Colore')?.value} />
      )}
    </div>
  );
};
