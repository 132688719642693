export const en = {
  customerCare: {
    contact: 'Contact us at',
  },
  form: {
    customerForm: {
      phoneNumbers: {
        label: 'Mobile phone',
        placeholder: 'Mobile phone',
      },
    },
    newsletterForm: {
      outcome: {
        success: {
          body: "We confirm that you have successfully subscribed to our newsletter! We'll send you updates about latest news, trends, and special deals customized to your preferences.",
          title: 'Welcome to the world of Mia Cosmetics!',
        },
      },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Mobile phone' },
    },
  },
  misc: {
    MIA_LIMITED: 'Limited edition',
    MIA_NEW: 'New in',
    article: { share: 'Share article' },
    share: 'Share',
    storeName: 'Mia Cosmetics',
  },
  product: {
    code: 'Cod',
    promo: 'Promo',
    relatedProduct: {
      title: 'Related products',
    },
    selectSize: 'Select color',
    size: '',
    size_one: 'Color',
    size_other: 'Colors',
  },
  storeLocator: {
    badges: {
      corner: 'Mia Corner',
      shop: 'Mia Shop',
    },
  },
  welcome: 'Welcome to Mia Cosmetics',
};
