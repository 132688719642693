import './BlogPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { ArticleTile } from '../ArticleTile';
import { Breadcrumb } from '../Breadcrumb';
import { Container } from '../Container';
import { ArticlePageContentType, BlogPageContentType } from '../contentTypes';
import { PageHeader } from '../PageHeader';
import { ErrorSearchPage } from './ErrorSearchPage';
import { Page } from './Page';

export interface BlogPageProps {}

export const BlogPage = (props: BlogPageProps) => {
  const { page, searchContentsResult } = b2x.useContentsSearch<BlogPageContentType, ArticlePageContentType>({
    defaultPageSize: 12,
    pageOptions: {
      populate: {
        breadcrumb: true,
        content: true,
      },
    },
    populate: { items: { pages: true } },
    type: 'ARTICLE_PAGE_CONTENT_TYPE',
  });

  const categories = b2x.useMenu('MENU_BLOG_CATEGORIES');

  return (
    <>
      {searchContentsResult?.itemsCount === 0 ? (
        <ErrorSearchPage />
      ) : (
        <Page className="blog-page border-bottom" noPaddingTop thingsToLoadBeforePageReady={[page]}>
          {page?.content?.body.header && <PageHeader {...page.content.body.header} className={'mb-lg-5'} />}

          <Container>
            <Breadcrumb contentCodeListToHide={['ART_BLOG_CATEGORY']} />

            {categories && (
              <b2x.Row className="my-3 my-md-5">
                <b2x.Col size={{ xs: 12 }}>
                  <div className="d-flex flex-nowrap first-level-category">
                    {categories.children.map((firstLevelCategory) => (
                      <b2x.router.Link
                        className={classnames('btn btn-outline-secondary align-self-start me-2 mb-2', {
                          active:
                            page?.breadcrumb &&
                            page.breadcrumb.length > 3 &&
                            firstLevelCategory.id === page.breadcrumb[3].id,
                        })}
                        key={firstLevelCategory.id}
                        to={firstLevelCategory.link}
                      >
                        {b2x.formatHtml(firstLevelCategory.label)}
                      </b2x.router.Link>
                    ))}
                  </div>
                </b2x.Col>
                {categories.children.map(
                  (firstLevelCategory) =>
                    firstLevelCategory.children.length > 0 && (
                      <b2x.Col
                        className={classnames(
                          page?.breadcrumb &&
                            page.breadcrumb.length > 3 &&
                            firstLevelCategory.id === page.breadcrumb[3].id
                            ? 'd-block'
                            : 'd-none'
                        )}
                        key={firstLevelCategory.id}
                        size={{ xs: 12 }}
                      >
                        <div className="bg-secondary d-flex flex-nowrap py-2 px-3 p-md-3 rounded second-level-category">
                          {firstLevelCategory.children.map((secondLevelCategory) =>
                            secondLevelCategory.id !== page?.id ? (
                              <b2x.router.Link
                                className={classnames('align-self-start me-4 small text-decoration-none')}
                                key={secondLevelCategory.id}
                                to={secondLevelCategory.link}
                              >
                                {b2x.formatHtml(secondLevelCategory.label)}
                              </b2x.router.Link>
                            ) : (
                              <span className="fw-bold small me-4" key={secondLevelCategory.id}>
                                {b2x.formatHtml(secondLevelCategory.label)}
                              </span>
                            )
                          )}
                        </div>
                      </b2x.Col>
                    )
                )}
              </b2x.Row>
            )}

            <b2x.Row className="justify-content-center my-3 my-lg-5">
              <b2x.Col className="text-center" size={{ md: 10, xs: 12 }}>
                {page?.metaData?.title && (
                  <h1 className="h2 mb-3 fw-semi-bold">{b2x.formatHtml(page.metaData.title)}</h1>
                )}
                {page?.metaData?.description && (
                  <div className="small">{b2x.formatHtml(page.metaData.description)}</div>
                )}
              </b2x.Col>
            </b2x.Row>
          </Container>

          <Container>
            <b2x.Row>
              <b2x.EqualHeight>
                {searchContentsResult &&
                  searchContentsResult.items &&
                  searchContentsResult.items.map((article) => (
                    <div className="col-lg-4 col-md-6 col-sm-6" key={article.id}>
                      <ArticleTile
                        classname="mb-3"
                        cta={article.url}
                        imagePreview={article.body.imagePreview}
                        onlyOneFormatForImagePreview
                        pages={article.pages}
                        title={article.body.title}
                      />
                    </div>
                  ))}
              </b2x.EqualHeight>
            </b2x.Row>

            {searchContentsResult && (
              <b2x.Div display="flex" justifyContent="center">
                <b2x.Pagination
                  currentPage={searchContentsResult.pageNumber}
                  pageOffset={2}
                  showDots
                  singleStepNavigation
                  totalPages={searchContentsResult.pagesCount}
                />
              </b2x.Div>
            )}
          </Container>
        </Page>
      )}
    </>
  );
};
