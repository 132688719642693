import './Footer.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppStaticContext } from './AppContext';
import { Container } from './Container';
import { FooterContentType } from './contentTypes';
import { FooterIcons } from './FooterIcons';
import { NewsletterForm } from './forms/NewsletterForm';
import { t } from './i18n/i18n';
import { ListAFromMenu } from './ListAFromMenu';
import { SocialIcons } from './SocialIcons';

export interface FooterProps {}

export const Footer = (props: FooterProps) => {
  const windowSize = b2x.useWindowSize();
  const [currentBreakpoint, setCurrentBreakpoint] = React.useState<string>(b2x.getCurrentBreakpoint());

  React.useEffect(() => {
    setCurrentBreakpoint(b2x.getCurrentBreakpoint());
  }, [windowSize, setCurrentBreakpoint]);

  const footerContent = b2x.useContent<FooterContentType>('FOOTER_CONTENT');
  const footerMenu = b2x.useMenu('MENU_FOOTER');
  const footerMenuSecondary = b2x.useMenu('FOOTER_SECONDARY_MENU');
  const footerBottomMenu = b2x.useMenu('MENU_BOTTOM_FOOTER');

  const { setFooterCopyrightRef } = useAppStaticContext();

  return (
    <footer className="footer">
      <div className="pre-footer-row bg-white py-lg-5 py-4">
        <FooterIcons />
      </div>
      <div className="bg-secondary">
        <Container>
          <div className="footer-first-row pt-3 py-lg-5">
            <b2x.Row>
              <b2x.Col className="footer-newsletter order-lg-2" size={{ lg: 4, xs: 12 }}>
                <div className="mb-3">
                  <div>
                    <h6 className={classnames('fw-bold mb-3', { h4: 'xs sm md'.includes(currentBreakpoint) })}>
                      {t('misc.newsletterTitle')}
                    </h6>
                    <p>{b2x.formatHtml(footerContent?.body.newsletterSubtitle)}</p>
                  </div>
                  <NewsletterForm
                    className="mb-4"
                    ctaContainerClassName="text-start text-lg-end"
                    emailFieldStyle="textInput"
                    source="website-footer"
                  />
                </div>
              </b2x.Col>
              <b2x.Col size={{ lg: 8, xs: 12 }}>
                <AccordionFooterMenu id="first-footer-accordion" menu={footerMenu} />
              </b2x.Col>
            </b2x.Row>
          </div>
          <div className="footer-second-row pb-lg-5">
            <b2x.Row>
              <b2x.Col size={{ lg: 8, xs: 12 }}>
                <AccordionFooterMenu id="second-footer-accordion" menu={footerMenuSecondary} />
              </b2x.Col>
              <b2x.Col size={{ lg: 4, xs: 12 }}>
                <div className="footer-social py-4 p-lg-0 d-flex align-items-center d-lg-block">
                  <h6 className="fw-bold me-3 mb-0 mb-lg-3">{t('misc.followUs')}</h6>
                  <div className="d-flex">
                    <SocialIcons />
                  </div>
                </div>
              </b2x.Col>
            </b2x.Row>
          </div>
        </Container>
      </div>
      <Container>
        <div className="footer-third-row extra-small py-3" ref={setFooterCopyrightRef}>
          <b2x.Row className="justify-content-between align-items-center">
            <b2x.Col>
              <div className="d-none d-lg-block text-uppercase">
                {footerBottomMenu && <ListAFromMenu menu={footerBottomMenu} textUppercase />}
              </div>
              {footerContent && <div>{b2x.formatHtml(footerContent.body.copyright)}</div>}
            </b2x.Col>
            <b2x.Col className="d-flex align-items-center mt-4 mt-lg-0" size={{ lg: 'auto', xs: 12 }}>
              <b2x.PaymentIcons />
            </b2x.Col>
          </b2x.Row>
        </div>
      </Container>
    </footer>
  );
};

interface AccordionFooterMenuProps {
  id: string;
  menu?: b2x.MenuApiDto;
}
const AccordionFooterMenu = ({ id, menu }: AccordionFooterMenuProps) => {
  const { session } = b2x.useAppContext();
  const { showAccountOffcanvas } = useAppStaticContext();
  return (
    <b2x.Accordion
      columnLayoutFrom="lg"
      id={id}
      itemBodyClassName="small pb-3 p-0"
      itemButtonClassName="bg-transparent fw-bold mb-lg-1 py-lg-0 py-3 p-0"
      itemClassName="bg-transparent"
    >
      {menu?.children.map(
        (column) =>
          column.label && (
            <b2x.AccordionItem id={`accordionItem-${column.id}`} key={column.code} title={column.label}>
              {column.children.map((li) =>
                li.code === 'SITE_CCN_AREA' ? (
                  session?.userLogged ? (
                    li.children.map(
                      (subLi) =>
                        subLi.link && (
                          <div key={subLi.code}>
                            <b2x.router.Link className="text-reset text-decoration-none d-block py-1" to={subLi.link}>
                              {subLi.label}
                            </b2x.router.Link>
                          </div>
                        )
                    )
                  ) : (
                    <div key={li.code}>
                      <b2x.BlankButton className="d-block py-1" onClick={showAccountOffcanvas}>
                        {t('account.login')}
                      </b2x.BlankButton>
                    </div>
                  )
                ) : (
                  li.link && (
                    <div key={li.code}>
                      {li.image ? (
                        <b2x.router.Link className={'mt-2 d-inline-block'} to={li.link}>
                          <b2x.Image {...li.image} fluid width={100} />
                        </b2x.router.Link>
                      ) : (
                        <b2x.router.Link className="text-reset text-decoration-none d-block py-1" to={li.link}>
                          {li.label}
                        </b2x.router.Link>
                      )}
                    </div>
                  )
                )
              )}
            </b2x.AccordionItem>
          )
      )}
    </b2x.Accordion>
  );
};
