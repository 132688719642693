import './SliderE.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { SliderEContentSection } from '../contentTypes';
import { VideoCard } from '../VideoCard';
import { Slider } from './Slider';
import { SliderButton } from './SliderButton';
export interface SliderEProps extends SliderEContentSection {}

export const SliderE = ({ cta, description, title, videoList }: SliderEProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);

  return (
    <div className="slider-e mt-5 overflow-hidden">
      <b2x.Row>
        <b2x.Col className="m-auto ps-0 pe-0 pe-lg-3" size={{ lg: 3, xl: 4, xs: 12 }}>
          <div className="mx-auto ms-lg-auto me-lg-0 ps-0 ps-lg-4 text-center text-lg-start" style={{ maxWidth: 300 }}>
            <h3 className="text-promo fw-bold">{title}</h3>
            <h5 className="fw-normal mt-3">{b2x.formatHtml(description)}</h5>
            {cta && cta.label && (
              <b2x.DeprecatedCta className="btn btn-primary mt-3" cta={cta}>
                {cta.label}
              </b2x.DeprecatedCta>
            )}
          </div>
        </b2x.Col>
        <b2x.Col className="position-relative" size={{ lg: 9, xl: 8, xs: 12 }}>
          <Slider
            autoplay={{ delay: 3000 }}
            className="mt-lg-0 mt-3 ps-3 ps-lg-0"
            loop
            navigation
            responsive={{
              lg: { slidesPerView: 2.25 },
              md: { slidesPerView: 2.25 },
              sm: { slidesPerView: 1.25 },
              xl: { slidesPerView: 3.25 },
            }}
            sliderNextEl={sliderNextButton}
            sliderPrevEl={sliderPrevButton}
            slidesPerView={1.1}
            spaceBetween={14}
            watchSlidesProgress
          >
            {videoList?.map(
              (item) =>
                item.videoSrc && (
                  <SwiperSlide key={item.contentSectionId}>
                    <VideoCard videoSrc={item.videoSrc} />
                  </SwiperSlide>
                )
            )}
          </Slider>
          <SliderButton direction="left" innerRef={sliderPrevButton} size="small" />
          <SliderButton direction="right" innerRef={sliderNextButton} size="small" />
        </b2x.Col>
      </b2x.Row>
    </div>
  );
};
