import './SliderArticles.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import { SwiperSlide } from 'swiper/react';

import { ArticleTile } from '../ArticleTile';
import { ArticlePageContentType, SliderArticlesContentSection } from '../contentTypes';
import { Section } from '../Section';
import { Slider } from './Slider';

export interface SliderArticlesProps extends SliderArticlesContentSection {
  articles?: Array<b2x.ContentApiDto<ArticlePageContentType>>;
  className?: string;
}

export const SliderArticles = ({ articles, className, cta, description, title }: SliderArticlesProps) => {
  return articles && articles.length > 0 ? (
    <Section className={classnames('slider-articles', className)}>
      <div className="mt-5 overflow-hidden">
        <b2x.Row>
          <b2x.Col className="m-auto order-lg-last" size={{ lg: 3, xl: 4, xs: 12 }}>
            <div
              className="mx-auto ms-lg-0 me-lg-auto ps-lg-4 pe-lg-3 text-center text-lg-start"
              style={{ maxWidth: 300 }}
            >
              <h3 className="text-promo fw-bold">{title}</h3>
              <h5 className="fw-normal mt-3">{b2x.formatHtml(description)}</h5>
              {cta && cta.label && (
                <b2x.DeprecatedCta className="btn btn-secondary mt-3" cta={cta}>
                  {cta.label}
                </b2x.DeprecatedCta>
              )}
            </div>
          </b2x.Col>
          <b2x.Col className="position-relative order-lg-first" size={{ lg: 9, xl: 8, xs: 12 }}>
            <b2x.EqualHeight>
              <Slider
                className="mt-lg-0 mt-3 pe-3 pe-lg-0"
                dir="rtl"
                loop
                navigation
                responsive={{
                  lg: { slidesPerView: 2.25 },
                  md: { slidesPerView: 2.25 },
                  sm: { slidesPerView: 1.25 },
                  xl: { slidesPerView: 3.25 },
                }}
                slidesPerView={1.1}
                spaceBetween={14}
                watchSlidesProgress
              >
                {articles.map((article) => (
                  <SwiperSlide key={article.id}>
                    <ArticleTile
                      classname="mb-0"
                      cta={article.url}
                      imagePreview={article.body.imagePreview}
                      onlyOneFormatForImagePreview
                      pages={article.pages}
                      title={article.body.title}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            </b2x.EqualHeight>
          </b2x.Col>
        </b2x.Row>
      </div>
    </Section>
  ) : (
    <></>
  );
};
