import { b2x } from '@b2x/react/src';
import React from 'react';

import { Container } from '../Container';
import { ArticlePageContentType, HomePageContentType } from '../contentTypes';
import { Section } from '../Section';
import { SliderA } from '../slider/SliderA';
import { SliderArticles } from '../slider/SliderArticles';
import { SliderB } from '../slider/SliderB';
import { SliderC } from '../slider/SliderC';
import { SliderE } from '../slider/SliderE';
import { Page } from './Page';
import { usePopUpNewsletterModal } from './PopupNewsletterModal';

export interface HomePageProps {}

export const HomePage = (props: HomePageProps) => {
  const page = b2x.usePage<HomePageContentType>({
    secondStep: {
      populate: {
        additionalContents: { pages: true },
        products: b2x.appConfig.api?.productTilePopulate,
      },
    },
  });

  const { pageReady } = b2x.useAppContext();

  const [PopupNewsLetterModal, showPopupNewsLetterModal] = usePopUpNewsletterModal({
    onClose: () => {
      const modalClosedTimes = (b2x.storage.getNumber('homePageNewsletterModalClosedTimes', true) ?? 0) + 1;
      b2x.storage.setNumber('homePageNewsletterModalClosedTimes', modalClosedTimes, true);
    },
  });

  React.useEffect(() => {
    const newsletterConsent = b2x.storage.getBoolean('newsletterConsent', true);
    if (newsletterConsent) {
      return;
    }

    const modalClosedTimes = b2x.storage.getNumber('homePageNewsletterModalClosedTimes', true) ?? 0;
    if (modalClosedTimes < 2 && pageReady) {
      b2x.wait(30000 * (modalClosedTimes + 1)).then(showPopupNewsLetterModal);
    }
  }, [pageReady, showPopupNewsLetterModal]);

  const body = page?.content?.body;

  return (
    <Page noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]} transparentHeader>
      {PopupNewsLetterModal}
      <Section>
        <SliderA {...page?.content?.body.mainSliderSection} />
      </Section>
      <Section className="bg-pink-light py-5">
        <Container>
          <b2x.Row>
            <b2x.Col>
              <div className="text-center mb-5">
                {page?.content?.body.mainCopySection?.title && (
                  <h1 className="fw-semi-bold">{b2x.formatHtml(page.content.body.mainCopySection.title)}</h1>
                )}
                {page?.content?.body.mainCopySection?.content && (
                  <p>{b2x.formatHtml(page.content.body.mainCopySection.content)}</p>
                )}
              </div>
            </b2x.Col>
          </b2x.Row>
        </Container>
        {page?.products && (
          <div className="mb-4 mb-lg-5">
            <b2x.Listing name={`Home page - ${page.content?.body.mainCopySection?.title}`} products={page.products}>
              <SliderB products={page.products} />
            </b2x.Listing>
          </div>
        )}
      </Section>
      <Section>
        <h2 className="text-promo text-center fw-bold mt-5">{body?.socialSection?.title}</h2>
        <SliderE {...body?.socialSection?.tutorialSection} />
      </Section>

      {page?.additionalContents && (
        <SliderArticles
          articles={b2x.getTypedContents<ArticlePageContentType>(page.additionalContents)}
          className="py-5"
          cta={page.content?.body.articlesSection?.cta}
          description={page.content?.body.articlesSection?.description}
          title={page.content?.body.articlesSection?.title}
        />
      )}

      {page?.content?.body.newsPromotionsSection?.itemList &&
        page.content.body.newsPromotionsSection.itemList.length > 0 && (
          <Section className="py-5">
            <Container>
              {page.content.body.newsPromotionsSection.title && (
                <h3 className="h2 text-center fw-semi-bold mb-5">{page.content.body.newsPromotionsSection.title}</h3>
              )}
            </Container>
            <SliderC itemList={page.content.body.newsPromotionsSection.itemList} />
          </Section>
        )}
      <Section className="py-3 pt-lg-5">
        {page?.content?.body.taggboxSection?.title && (
          <Container>
            <b2x.Row>
              <b2x.Col>
                <div className="text-center mb-5">
                  <h3 className="h2 fw-semi-bold">{b2x.formatHtml(page.content.body.taggboxSection.title)}</h3>
                  {page.content.body.taggboxSection.content && (
                    <p>{b2x.formatHtml(page.content.body.taggboxSection.content)}</p>
                  )}
                </div>
              </b2x.Col>
            </b2x.Row>
          </Container>
        )}
        <p>
          <iframe
            src="https://widget.taggbox.com/133637"
            style={{ border: 'none', height: '350px', width: '100%' }}
            title="taggbox"
          ></iframe>
        </p>
      </Section>
    </Page>
  );
};
