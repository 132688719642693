import './VideoCard.scss';

import { b2x } from '@b2x/react/src';

export interface VideoCardProps {
  videoSrc: string;
}

export const VideoCard = ({ videoSrc }: VideoCardProps) => {
  return (
    <div className="video-card rounded-2 overflow-hidden">
      <b2x.DeprecatedVideo
        controls
        src={{
          xs: { video: videoSrc },
        }}
      ></b2x.DeprecatedVideo>
    </div>
  );
};
