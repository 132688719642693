import './StoreLocatorPage.scss';

import { b2x } from '@b2x/react/src';

import { ButtonVariant } from '../Button';
import { t } from '../i18n/i18n';
import { IconName, IconSize } from '../Icon';
import pin1 from '../images/pin1.png';
// import pin2 from '../images/pin2.png';
import pin3 from '../images/pin3.png';
import { Page } from './Page';

export interface StoreLocatorPageProps {}

export const StoreLocatorPage = (props: StoreLocatorPageProps) => {
  return (
    <Page hiddenFooter noPaddingBottom noPaddingTop>
      <div className="h-100">
        <b2x.StoreLocator<ButtonVariant, IconName, IconSize>
          debug
          filtersToggleButtonVariant="outline-primary"
          getDetailsPanelMarkerContent={getDetailsPanelMarkerContent}
          getMarkerContent={getMarkerContent}
          getShopBadges={getShopBadges}
          getShopDescription={getShopDescription}
          getShopName={getShopName}
          iconArrowLeft="large-arrow-left"
          iconArrowRight="large-arrow-right"
          iconClose="delete"
          iconDirections="arrow-map"
          iconFilter="filter-map"
          iconMyLocation="geolocator"
          iconPhoneCall="phone"
          iconPin="store-locator"
          iconSearch="search"
          iconShare="share"
          iconSpeechRecognition="voice-recorder"
          iconSpeechRecognitionActive="voice-recorder-active"
          iconZoomIn="plus"
          iconZoomOut="minus"
          iconsSize={24}
          shopTileHeadingSize={6}
          variantPrimary="primary"
          variantSecondary="outline-primary"
        />
      </div>
    </Page>
  );
};

type ShopType = 'shop' | 'corner';

export const getShopName = (shop: b2x.ShopApiDto) => shop.name ?? '';

export const getShopDescription = (shop: b2x.ShopApiDto) => shop.description ?? '';

const getMarkerContent = (shop: b2x.ShopApiDto): b2x.MarkerContent => {
  const shopType = getShopType(shop);
  return {
    image: {
      src: shopType === 'shop' ? pin1 : shopType === 'corner' ? pin3 : pin1,
    },
  };
};

const getDetailsPanelMarkerContent = (shop: b2x.ShopApiDto): b2x.MarkerContent => {
  const shopType = getShopType(shop);
  return {
    image: {
      src: shopType === 'shop' ? pin1 : shopType === 'corner' ? pin3 : pin1,
      width: 30,
    },
  };
};

const getShopType = (shop: b2x.ShopApiDto): ShopType | undefined => {
  const shopType = shop.tags
    ?.find((tag) => tag.startsWith('TAG_'))
    ?.replace('TAG_', '')
    .toLowerCase();

  return shopType === 'shop' || shopType === 'corner' ? shopType : undefined;
};

const getShopBadges = (shop: b2x.ShopApiDto): Array<b2x.BadgeProps<string>> => {
  const shopType = getShopType(shop);
  return shopType
    ? [
        {
          backgroundVariant: shopType === 'shop' ? 'plum' : 'mint',
          children: t(`storeLocator.badges.${shopType}`),
          textVariant: shopType === 'shop' ? 'white' : 'primary',
        },
      ]
    : [];
};
