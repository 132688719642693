import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import { OffcanvasHeader } from './Offcanvas';

interface SearchFiltersOffcanvasProps extends b2x.OffcanvasProps {
  searchResult?: b2x.SearchProductsApiDto;
}

const SearchFiltersOffcanvas = ({ searchResult, ...offcanvasProps }: SearchFiltersOffcanvasProps) => {
  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
  ]);
  return (
    <b2x.Offcanvas {...offcanvasProps}>
      {({ close }) => (
        <>
          <OffcanvasHeader smallPadding title="Filtra" />
          <b2x.OffcanvasBody className="px-3 py-0">
            <b2x.SearchFormHelper className="position-relative h-100" searchResult={searchResult} submitOnChange>
              {({ fieldsHelper, formik }) => {
                return (
                  <b2x.Drawers itemsClassName="border-bottom px-3 py-1">
                    <div className="bg-lighter p-3 mb-3 bg-pink-light">
                      <div className="fw-light small text-dark mb-2">{t('misc.orderBy')}</div>
                      <div className="d-flex gap-2 flex-wrap ">
                        {sortingOptions.map((sortingOption) => (
                          <Button
                            className="text-nowrap text-lowercase"
                            key={sortingOption.label}
                            label={sortingOption.label}
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={() => {
                              sortingOption.onClick();
                              close();
                            }}
                            variant={sortingOption.active ? 'primary' : 'outline-primary'}
                          />
                        ))}
                      </div>
                    </div>
                    <p className="fw-light small text-dark">Filtra i risultati della tua ricerca</p>
                    <DrawerSearchFiltersItems close={close} items={fieldsHelper.filters} textUppercase />
                  </b2x.Drawers>
                );
              }}
            </b2x.SearchFormHelper>
          </b2x.OffcanvasBody>
        </>
      )}
    </b2x.Offcanvas>
  );
};

interface DrawerBackItemProps {
  label?: string;
}

const DrawerBackItem = ({ label = 'Indietro' }: DrawerBackItemProps) => {
  const { closeActiveDrawer } = b2x.useDrawersContext();

  return (
    <b2x.DrawerButtonItem onClick={closeActiveDrawer}>
      <b2x.DrawerItem
        center={{ center: label }}
        className="extra-small fw-light gap-3"
        end={{ center: <Icon className="invisible" name="small-arrow-left" /> }}
        height={35}
        justifyContent="between"
        start={{ center: <Icon name="small-arrow-left" /> }}
      />
    </b2x.DrawerButtonItem>
  );
};

interface DrawerSearchFiltersItemsProps {
  close(): void;
  fontWeight?: 'medium' | 'light';
  items: Array<b2x.SearchFilter>;
  previousItem?: b2x.SearchFilter;
  textUppercase?: boolean;
}

const DrawerSearchFiltersItems = ({ close, items, previousItem, textUppercase }: DrawerSearchFiltersItemsProps) => {
  return (
    <>
      {items.map((item) => {
        const hasChildren = item.children.length > 0;
        const checkedChildren = item.children.filter((child) => child.filter.checkType === 'CHECKED');

        return hasChildren ? (
          <b2x.DrawerTriggerItem
            key={item.filter.id}
            newDrawer={
              <Drawer backItemProps={{ label: item.filter.name }}>
                <DrawerSearchFiltersItems close={close} items={item.children} previousItem={item} textUppercase />
              </Drawer>
            }
          >
            <b2x.DrawerItem
              className={classnames('small')}
              end={{ center: <Icon name="small-arrow-right" /> }}
              height={55}
              justifyContent="between"
              start={{
                bottom:
                  checkedChildren.length > 0 ? (
                    <div className="extra-small">{checkedChildren.map((child) => child.filter.name).join(', ')}</div>
                  ) : undefined,
                top: (
                  <span
                    className={classnames(
                      item.filter.checkType === 'CHECKED' || item.filter.checkType === 'CHILD_CHECKED'
                        ? 'fw-bold'
                        : 'fw-normal',
                      { 'text-uppercase': textUppercase }
                    )}
                  >
                    {item.filter.name}
                  </span>
                ),
              }}
            />
          </b2x.DrawerTriggerItem>
        ) : (
          <b2x.DrawerCheckboxItem key={item.filter.id} {...item.checkbox}>
            <b2x.DrawerItem
              className={classnames('small', {
                'fw-bold': item.filter.checkType === 'CHECKED',
              })}
              end={{
                center: item.filter.checkType === 'CHECKED' ? <Icon name="flag" /> : undefined,
              }}
              height={55}
              justifyContent="between"
              start={{ center: item.filter.name }}
            />
          </b2x.DrawerCheckboxItem>
        );
      })}
      {previousItem && (
        <b2x.DrawerCheckboxItem {...previousItem.checkbox}>
          <b2x.DrawerItem
            className={classnames('small', {
              'fw-bold': previousItem.filter.checkType === 'CHECKED',
            })}
            end={{
              center: previousItem.filter.checkType === 'CHECKED' ? <Icon name="flag" /> : undefined,
            }}
            height={55}
            justifyContent="between"
            start={{ center: 'Seleziona tutto' }}
          />
        </b2x.DrawerCheckboxItem>
      )}
    </>
  );
};

interface DrawerProps {
  backItemProps?: DrawerBackItemProps;
  children: React.ReactNode;
}

const Drawer = ({ backItemProps, children }: DrawerProps) => {
  return (
    <div className="d-flex flex-column h-100 overflow-auto">
      <DrawerBackItem {...backItemProps} />
      <div className="bg-white flex-grow-1 overflow-auto">{children}</div>
    </div>
  );
};

export const useSearchFiltersOffcanvas = (props?: SearchFiltersOffcanvasProps) =>
  b2x.useStaticModal(SearchFiltersOffcanvas, 'offcanvas', props);
