import './Icon.scss';

import { b2x } from '@b2x/react/src';

export const iconsNames = [
  'arrow-map',
  'phone',
  'geolocator',
  'voice-recorder',
  'voice-recorder-active',
  'filter-map',
  'info',
  'pinterest',
  'store-locator',
  'store-mia',
  'store-rivenditore',
  'tik-tok',
  'translate',
  'vegan',
  'wishlist',
  'wishlist-full',
  'youtube',
  'account',
  'account-full',
  'call-center',
  'cart',
  'cart-full',
  'clean',
  'coupon',
  'credit-card',
  'cruelty-free',
  'delete',
  'facebook',
  'filter',
  'flag',
  'gluten-free',
  'instagram',
  'large-arrow-left',
  'large-arrow-right',
  'linkedin',
  'logout',
  'menu',
  'minus',
  'newsletter',
  'next',
  'no-parabens',
  'pause',
  'play',
  'plus',
  'previous',
  'privacy',
  'referral',
  'search',
  'share',
  'small-arrow-down',
  'small-arrow-left',
  'small-arrow-right',
  'small-arrow-up',
  'visible',
  'hidden',
] as const;
export type IconName = (typeof iconsNames)[number];

export type IconSize = 12 | 14 | 16 | 20 | 24 | 25 | 30 | 35 | 48 | 72;

export interface IconProps extends b2x.IconProps<IconName, IconSize> {}

export const Icon = (props: IconProps) => <b2x.Icon {...props} />;

export const isIconName = (name?: string): name is IconName => b2x.isIconName(iconsNames, name);
