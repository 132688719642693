import './MobileHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import logo from './images/logo.svg';
import logoTransparent from './images/logo-transparent.svg';
//import { useMmenuOffcanvas } from './Mmenu';
import { useMobileNavigationOffcanvas } from './MobileNavigationOffcanvas';
import { useMobileSimpleSearchOffcanvas } from './MobileSimpleSearchOffcanvas';

export interface MobileHeaderProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  innerRef: React.RefObject<HTMLDivElement>;
  recalculateHeaderHeight(): void;
  visibleUntil: b2x.Breakpoint;
}

export const MobileHeader = ({ content, innerRef, recalculateHeaderHeight, visibleUntil }: MobileHeaderProps) => {
  // const [Mmenu, showMmenu] = useMmenuOffcanvas({
  //   showAccountOffcanvas: showAccountOffcanvas,
  //   showCartOffcanvas: showCartOffcanvas,
  // });

  const { session, shippingCountry } = b2x.useAppContext();
  const { headerCheckout } = useAppContext();

  const [MobileNavigationOffcanvas, showMobileNavigationOffcanvas] = useMobileNavigationOffcanvas();
  const [MobileSimpleSearchOffcanvas, showMobileSimpleSearchOffcanvas] = useMobileSimpleSearchOffcanvas();

  const { transparentHeader } = useAppContext();
  const { showCartOffcanvas } = useAppStaticContext();

  return !headerCheckout ? (
    <>
      {/* {Mmenu} */}
      {MobileNavigationOffcanvas}
      {MobileSimpleSearchOffcanvas}
      <b2x.Sticky>
        {({ isSticky }) => (
          <div
            className={classnames(` sticky-top MobileHeader d-block d-${visibleUntil}-none border-bottom`, {
              transparent: transparentHeader && !isSticky,
            })}
            ref={innerRef}
          >
            <Container>
              <b2x.Row className={classnames('align-items-center')}>
                <b2x.Col className="d-flex">
                  {/* <Button iconEnd={{ name: 'menu', size: 30 }} onClick={showMmenu} variant="blank" /> */}
                  <Button
                    className={classnames('text-decoration-none')}
                    iconEnd={{ name: 'menu', size: 30 }}
                    onClick={showMobileNavigationOffcanvas}
                    variant="blank"
                  />
                </b2x.Col>
                <b2x.Col className="d-flex flex-grow-0">
                  <b2x.router.Link to="/">
                    <b2x.Image
                      className="logo-transparent d-none"
                      onLoad={recalculateHeaderHeight}
                      src={logoTransparent}
                    />
                    <b2x.Image className="logo d-none" onLoad={recalculateHeaderHeight} src={logo} />
                  </b2x.router.Link>
                </b2x.Col>
                <b2x.Col className="d-flex justify-content-end">
                  <div className="hstack gap-2">
                    <Button
                      className={classnames('text-decoration-none')}
                      iconEnd={{ name: 'search', size: 30 }}
                      onClick={showMobileSimpleSearchOffcanvas}
                      variant="blank"
                    />
                    {session?.customer && (
                      <b2x.router.Link className="lh-1" to="/account/area/wishlist">
                        <Button className={classnames('position-relative')} variant="blank">
                          <Icon name={'wishlist'} size={30} />
                          {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                            (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                              {(session.wishlist.products ? session.wishlist.products.length : 0) +
                                (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                            </span>
                          )}
                        </Button>
                      </b2x.router.Link>
                    )}

                    <Button className={classnames('position-relative')} onClick={showCartOffcanvas} variant="blank">
                      <Icon name={'cart'} size={30} />
                      {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
                        <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                          {session.cart.itemsNumber}
                        </span>
                      )}
                    </Button>
                  </div>
                </b2x.Col>
              </b2x.Row>
            </Container>
          </div>
        )}
      </b2x.Sticky>
    </>
  ) : (
    <div className={classnames(`MobileHeader sticky-top d-block d-${visibleUntil}-none border-bottom`)}>
      <div className="bg-lighter py-2 text-center text-uppercase extra-small text-dark fw-light">
        {session?.cart?.shippingProfile?.freeThreshold &&
          t('header.freeShippingFrom', {
            from: b2x.formatCurrency(session.cart.shippingProfile.freeThreshold),
            locale: shippingCountry?.name,
          })}
      </div>
      <div className="text-center py-2 bg-white">
        <b2x.Image onLoad={recalculateHeaderHeight} src={logo} />
      </div>
    </div>
  );
};
