/* eslint-disable react/forbid-elements */
/* eslint-disable react/jsx-no-bind */
import { b2x } from '@b2x/react/src';
import React from 'react';

import { Button } from '../Button';
import { Container } from '../Container';
import { LandingPartnerPageContentType } from '../contentTypes';
import { Page } from './Page';

export interface LandingPartnerPageProps {}

export const LandingPartnerPage = (props: LandingPartnerPageProps) => {
  const page = b2x.usePage<LandingPartnerPageContentType>();

  const startDownload = React.useCallback(() => {
    page &&
      page.content &&
      page.content.body.urlFileToDownload &&
      b2x.downloadFileFromUrl(`${page.content.body.urlFileToDownload}.pdf`, 'mimmo');
  }, [page]);

  return (
    <Page className="landing-page-partner" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <Container>
        {page?.content?.body.urlFileToDownload && <Button label={'Download'} onClick={startDownload} />}
      </Container>
    </Page>
  );
};
