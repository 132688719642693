import './DesktopHeader.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { HeaderContentType } from './contentTypes';
import { DesktopHeader } from './DesktopHeader';
import { t } from './i18n/i18n';
import { MobileHeader } from './MobileHeader';
import { VerticalSlider } from './slider/VerticalSlider';

export interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  const content = b2x.useContent<HeaderContentType>('HEADER_CONTENT');
  const mobileHeaderRef = React.useRef<HTMLDivElement>(null);
  const desktopHeaderSecondRowRef = React.useRef<HTMLDivElement>(null);

  const { headerFirstRowRef } = useAppContext();
  const { setHeaderHeight } = useAppStaticContext();
  const { headerCheckout } = useAppContext();

  const { width } = b2x.useWindowSize();

  const recalculateHeaderHeight = React.useCallback(() => {
    const currentBreakpoint = b2x.getCurrentBreakpoint();
    if (['xs', 'sm', 'md'].includes(currentBreakpoint)) {
      // Mobile
      if (mobileHeaderRef.current) {
        setHeaderHeight(mobileHeaderRef.current.clientHeight);
      }
    } else {
      // Desktop
      if (headerFirstRowRef.current && desktopHeaderSecondRowRef.current) {
        setHeaderHeight(headerFirstRowRef.current.clientHeight + desktopHeaderSecondRowRef.current.clientHeight);
      }
    }
  }, [headerFirstRowRef, desktopHeaderSecondRowRef, mobileHeaderRef, setHeaderHeight]);

  React.useEffect(() => {
    recalculateHeaderHeight();
  }, [recalculateHeaderHeight, width]);

  return (
    <>
      {/* <div className="alert alert-primary position-fixed" style={{ top: 100, zIndex: 9999 }}>
        {b2x.getCurrentBreakpoint()}
      </div> */}
      <TopBar content={content} />

      <MobileHeader
        content={content}
        innerRef={mobileHeaderRef}
        recalculateHeaderHeight={recalculateHeaderHeight}
        visibleUntil="lg"
      />
      <DesktopHeader
        content={content}
        recalculateHeaderHeight={recalculateHeaderHeight}
        secondRowRef={desktopHeaderSecondRowRef}
        visibleFrom="lg"
      />
      {headerCheckout && (
        <b2x.Div className="check-out-back">
          <b2x.Container>
            <b2x.Div display={'flex'} dynamicPadding={{ bottom: 3, top: 3 }}>
              <b2x.router.Link className="checkout-back-link" to="/cart">
                <b2x.Div display={'flex'} gap={1}>
                  {b2x.appConfig.icons?.arrow?.left && (
                    <div>
                      <b2x.Icon name={b2x.appConfig.icons.arrow.left} />
                    </div>
                  )}
                  <div>{t('checkout.backToCart')}</div>
                </b2x.Div>
              </b2x.router.Link>
            </b2x.Div>
          </b2x.Container>
        </b2x.Div>
      )}
    </>
  );
};

interface TopBarProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
}

const TopBar = ({ content }: TopBarProps) => {
  const topBarRef = React.useRef<HTMLDivElement>(null);
  const { setTopBarDesktopHeight } = useAppStaticContext();

  React.useEffect(() => {
    setTopBarDesktopHeight(topBarRef.current?.clientHeight);
  }, [setTopBarDesktopHeight, topBarRef]);

  return (
    <div
      className={'text-center'}
      ref={topBarRef}
      style={{
        background: content?.body.backgroundColor ? content.body.backgroundColor : '#F6F6F6',
        color: content?.body.textColor ? content.body.textColor : '#000000',
      }}
    >
      {content && content.body.messagesList ? (
        <VerticalSlider {...content.body} />
      ) : (
        <span className="extra-small">{t('welcome')}</span>
      )}
    </div>
  );
};
