import classnames from 'classnames';

export interface SectionProps {
  children: React.ReactNode;
  className?: string;
}

export const Section = ({ children, className }: SectionProps) => {
  return <section className={classnames('section', className)}>{children}</section>;
};
